<header class="header" id="header">
  <div class="header-container">
    <div class="logo-section">
      <div class="responsive-section" *ngIf="innerWidth < 1025">
        <div class="hamburger-container" (click)="toggleMenu()">
          <img src="assets/icon/hamburger-black.svg" alt="hamburger" width="20" height="20">
        </div>
        <div class="hamburger-menu" [class.shown]="!!showMenu">
          <div class="link" *ngIf="!isLoggedIn">
            <a class="tertiary" data-cy="signInHeader" [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu()">Sign in</a>
          </div>
          <div class="link menu-item" *ngIf="!!isLoggedIn">
            <p class="tertiary" data-cy="accountMenuHeader" (click)="showSubMenu('account')">Account <img
                src="assets/icon/black-arrow-down.svg" alt="arrow" [class.rotate]="subMenu === 'account'" /></p>
            <div class="sub-wrapper" [class.shown]="subMenu=== 'account'">
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="account-summary-header"
                  data-cy="account-summary-header">Account Summary</a>
              </div>
              <div class="link" *ngIf="!!displayAcpSection">
                <a class="tertiary" data-cy="acp-application-header" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ACP_APPLICATION]"
                  id="acp-application-header">ACP Application</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="purchased-plans-header"
                  data-cy="purchased-plans-header">Purchased Plans</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="refill-account-header" data-cy="refill-account-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]">Pay & Refill your
                  Account</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]"
                  id="refer-friend-header-unlogged-desktop" data-cy="refer-friend-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}">Refer
                  a Friend</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" routerLinkActive="active"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS]"
                  [routerLinkActiveOptions]="{exact: true}" [class.disabled]="!!isExpiredAccount || !!isPortIn"
                  id="plan-addOns-header" data-cy="plan-addOns-header">Plan Add-ons</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="your-orders-header" data-cy="your-orders-header">Your
                  Orders</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="payment-history-header" data-cy="payment-history-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAYMENTS]">Payment History</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn"
                  id="usage-history-header" data-cy="usage-history-header" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.USAGE]">Usage History</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="manage-device-header"
                  data-cy="manage-device-header">Manage Device & SIM</a>
              </div>
              <div class="link">
                <a class="tertiary" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" id="profile-setting-header"
                  data-cy="profile-setting-header">Profile Settings</a>
              </div>
              <div class="link">
                <p class="tertiary" (click)="toggleMenu();showSubMenu(null)" (click)="logout($event)" id="logout-header"
                  data-cy="logout-header">Logout</p>
              </div>
            </div>
          </div>
          <div class="link menu-item">
            <p class="tertiary" data-cy="shopHeader" (click)="showSubMenu('shop')">Shop <img
                src="assets/icon/black-arrow-down.svg" alt="arrow" [class.rotate]="subMenu === 'shop'" width="16" height="16"></p>
            <div class="sub-wrapper" [class.shown]="subMenu === 'shop'"
              *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
              <div class="link">
                <a class="tertiary" data-cy="plansHeader" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Plans</a>
              </div>
              <div class="link">
                <a class="tertiary" data-cy="phonesHeader" (click)="toggleMenu();showSubMenu(null)"
                  [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Phones</a>
              </div>
            </div>
            <div class="sub-wrapper active-plans" [class.shown]="subMenu === 'shop'"
              *ngIf="!!isLoggedIn && !!userHasActivePlans">
              <div class="new-account-part">
                <p class="title">New Account:</p>
                <p class="sub-title">
                  <button class="button thirdly" (click)="toggleMenu();showSubMenu(null)" data-cy="plansHeader"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Purchase
                    new Plan</button>
                </p>
                <p class="sub-title last">
                  <button class="button thirdly" (click)="toggleMenu();showSubMenu(null)" data-cy="phonesHeader"
                    [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]">Purchase
                    Phone</button>
                </p>
              </div>
              <div class="user-account-part">
                <div class="title-flex" [class.bottom]="!!showMdnList" (click)="showMdnList =! showMdnList">
                  <p class="title">Your Account:</p>
                  <img src="assets/icon/arrow-purple.svg" [class.rotate]="!!showMdnList" width="20" height="20"
                    alt="Arrow Icon">
                </div>
                <select *ngIf="!!showMdnList" class="mdn-list" id="phonePlan" name="phonePlan"
                  [(ngModel)]="selectedPlan" (ngModelChange)="userPlanSelected($event)" [disabled]="disabled"
                  [compareWith]="planCompare">
                  <option *ngFor="let plan of userPlans" [ngValue]="plan">
                    {{getSelectorTitle(plan)}}
                  </option>
                </select>
                <p class="sub-title">
                  <button class="button thirdly" (click)="changePlan();toggleMenu();showSubMenu(null)" [class.disabled]="!!isPortIn">Change
                    Plan</button>
                </p>
                <p class="sub-title last">
                  <button class="button thirdly" (click)="goToAddOns();toggleMenu();showSubMenu(null)"
                    [class.disabled]="!!isPortIn || !!isExpiredAccount">Order Add-ons</button>
                </p>
              </div>
            </div>
          </div>
          <div class="link">
            <a class="tertiary" id="check-your-phone-header" data-cy="check-your-phone-header" (click)="toggleMenu()"
              [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Bring
              your Phone</a>
          </div>
          <div class="link">
            <a class="tertiary" id="coverage-header" data-cy="coverage-header" (click)="toggleMenu()"
              [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Coverage</a>
          </div>
          <div class="link">
            <a class="tertiary" id="activate" data-cy="activate" (click)="toggleMenu()"
              [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Activate</a>
          </div>
          <div class="link">
            <a class="tertiary" id="refer-friend-header-logged-desktop" data-cy="refer-friend-header"
              (click)="toggleMenu()" [routerLink]="[ROUTE_URLS.REFER_FRIEND]" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Refer a Friend</a>
          </div>
        </div>
      </div>
      <a [routerLink]="[ROUTE_URLS.HOME]" (click)="showMenu=false; subMenu=null;allowScrolling()">
        <img src="assets/img/logo.png" class="logo" width="106" height="40" alt="Good2Go Mobile">
      </a>
    </div>
    <div class="header-links" *ngIf="innerWidth > 1024.9">
      <div class="link menu-item">
        <p class="tertiary" data-cy="shopHeader">Shop <img src="assets/icon/black-arrow-down.svg" alt="arrow" width="16" height="16"></p>
        <div class="wrapper" *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
          <div class="link">
            <a class="tertiary" data-cy="plansHeader"
              [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Plans</a>
          </div>
          <div class="link">
            <a class="tertiary" data-cy="phonesHeader"
              [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Phones</a>
          </div>
        </div>
        <div class="wrapper active-plans" *ngIf="!!isLoggedIn && !!userHasActivePlans">
          <div class="user-account-part">
            <div class="mdn-cell">
              <p class="title">Your Account</p>
              <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                (ngModelChange)="userPlanSelected($event)" [disabled]="disabled" [compareWith]="planCompare">
                <option *ngFor="let plan of userPlans" [ngValue]="plan">
                  {{getSelectorTitle(plan)}}
                </option>
              </select>
            </div>
            <p class="sub-title"> 
              <button class="button thirdly" data-cy="changePlanHeader" (click)="changePlan()" [class.disabled]="!!isPortIn">Change Plan</button>
            </p>
            <p class="sub-title last">
              <button class="button thirdly" data-cy="orderAddOnsHeader" (click)="goToAddOns()"
                [class.disabled]="!!isPortIn || !!isExpiredAccount">Order Add-ons</button>
            </p>
          </div>
          <div class="border"></div>
          <div class="new-account-part">
            <p class="title">New Account</p>
            <p class="sub-title">
              <button class="button thirdly" data-cy="plansHeader"
                [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+SHOP_ROUTE_URLS.PLANS_AND_FEATURES+'/'+PLANS_SHOP_ROUTE_URLS.NEW_PLAN]">Purchase
                new Plan</button>
            </p>
            <p class="sub-title last">
              <button class="button thirdly" data-cy="phonesHeader"
                [routerLink]="[SHOP_ROUTE_URLS.BASE+'/'+PHONES_SHOP_ROUTE_URLS.BASE + '/' + PHONES_SHOP_ROUTE_URLS.TYPE]">Purchase
                Phone</button>
            </p>
          </div>
        </div>
      </div>
      <div class="link">
        <a class="tertiary" id="check-your-phone-header" data-cy="check-your-phone-header"
          [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Bring
          your Phone</a>
      </div>
      <div class="link">
        <a class="tertiary" id="coverage-header" data-cy="coverage-header"
          [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.LANDING_COVERAGE]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Coverage</a>
      </div>
      <div class="link">
        <a class="tertiary" id="activate" data-cy="activate"
          [routerLink]="[ACTIVATION_ROUTE_URLS.BASE+'/'+ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Activate</a>
      </div>
      <div class="link">
        <a class="tertiary" id="refer-friend-header-logged-desktop" data-cy="refer-friend-header-link"
          [routerLink]="[ROUTE_URLS.REFER_FRIEND]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Refer a Friend</a>
      </div>
    </div>
    <div class="login-icons-links">
      <div class="link" *ngIf="!isLoggedIn && innerWidth > 1024.9">
        <a class="tertiary" [routerLink]="[LOGIN_ROUTE_URLS.BASE+'/'+LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Sign in</a>
      </div>
      <div class="link menu-item" *ngIf="!!isLoggedIn && innerWidth > 1024.9">
        <p class="tertiary" data-cy="accountMenuHeader">Account <img src="assets/icon/black-arrow-down.svg" alt="arrow" width="16" height="16">
        </p>
        <div class="wrapper account">
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.SUMMARY]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="account-summary-header"
              data-cy="account-summary-header">Account Summary</a>
          </div>
          <div class="link" *ngIf="!!displayAcpSection">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ACP_APPLICATION]"
              id="acp-application-header">ACP Application</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="purchased-plans-header"
              data-cy="purchased-plans-header">Purchased Plans</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="refill-account-header" data-cy="refill-account-header"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAY_AND_RENEW]">Pay & Refill your
              Account</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.REFER_FRIEND]"
              id="refer-friend-header-unlogged-desktop" data-cy="refer-friend-header" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}">Refer
              a Friend</a>
          </div>
          <div class="link">
            <a class="tertiary" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [class.disabled]="!!isExpiredAccount || !!isPortIn"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS]" id="plan-addOns-header"
              data-cy="plan-addOns-header">Plan Add-ons</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.ORDERS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="your-orders-header">Your
              Orders</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="payment-history-header"
              data-cy="payment-history-header" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.PAYMENTS]">Payment History</a>
          </div>
          <div class="link">
            <a class="tertiary" [class.disabled]="!!isPortIn" id="usage-history-header" data-cy="usage-history-header"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
              [routerLink]="[ACCOUNT_ROUTE_URLS.BASE + '/' + ACCOUNT_ROUTE_URLS.USAGE]">Usage History</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.MANAGE_DEVICES]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="manage-device-header"
              data-cy="manage-device-header">Manage Device & SIM</a>
          </div>
          <div class="link">
            <a class="tertiary" [routerLink]=" [ACCOUNT_ROUTE_URLS.BASE + '/'+ACCOUNT_ROUTE_URLS.SETTINGS]"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" id="profile-setting-header"
              data-cy="profile-setting-header">Profile Settings</a>
          </div>
          <div class="link">
            <p class="tertiary" (click)="logout($event)" id="logout-header" data-cy="logout-header">Logout</p>
          </div>
        </div>
      </div>
      <div class="border" *ngIf="innerWidth > 1024.9"></div>
      <div class="icon-link">
        <img width="24" height="24" src="assets/icon/cart black.svg" alt="cart icon" (click)="goToCart()">
        <span class="counter" *ngIf="hasCartItem && totalItems !== 0">{{totalItems}}</span>
      </div>
      <div class="icon-link">
        <img width="24" height="24" src="assets/icon/bell black.svg" alt="notification icon" (click)="getPriority()">
        <span class="counter" *ngIf="!!isLoggedIn && notificationCounter > 0">{{notificationCounter}}</span>
      </div>
    </div>
  </div>
</header>
<div class="alert-banner" *ngIf="!!mantainenceDescription && !!displayMaintenanceBanner">
  <img class="alert-icon" src="assets/icon/alert.svg" alt="alert">
  <div class="message" [innerHTML]="mantainenceDescription"></div>
</div>
<div class="ios-upgrade-banner" *ngIf="!!isLoggedIn && !isCloseBanner && !isOnIosLandingPage && !showOutage && !showACPNotice">
  <div class="flex">
    <div class="flex-title">
      <img src="assets/icon/header-warning-icon.svg" class="warning-icon" width="28" height="28" alt="Warning Icon">
      <div class="banner-content">
        <p class="smaller"><b>iOS 17 Update Information - AT&T powered accounts.<a class="view-action"
              (click)="isMoreDetails =! isMoreDetails">View {{!isMoreDetails ? 'More' : 'Less'}}</a></b></p>
        <ng-container *ngIf="!!isMoreDetails">
          <p class="smaller">iPhone <b>iOS version 17</b> is now <b>available</b> for <b>iPhone XR</b> and <b>up</b>.
            Users who have taken the update may be able to <b>change</b> a <b>setting</b> on the phone which would
            <b>enable MMS services</b>.
          </p>
          <p class="smaller last">For more information, go to <a
              [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.IOS17]"
              (click)="isMoreDetails = false">iOS 17 Instructions
              page</a></p>
        </ng-container>
      </div>
    </div>
    <img src="assets/icon/header-close-icon.svg" (click)="closeBanner()" class="close-icon" width="24" height="24"
      alt="Warning Icon">
  </div>
</div>
<div class="outage-banner" *ngIf="!!showOutage">
  <div class="flex">
      <img src="assets/icon/banner-alert-icon.svg" class="alert-icon" width="23" height="23" alt="alert Icon">
      <div class="banner-content">
        <p class="smaller"><b>Network is currently experiencing a major service outage, impacting large portions of the country.</b></p>
       <p class="smaller">Engineers are working to restore service. At this time, there is no ETA for restoral, but we expect a quick resolution to this critical service outage.</p>
      </div>
  </div>
</div>
<div class="outage-banner" *ngIf="!!showOutage">
  <div class="flex">
      <img src="assets/icon/banner-alert-icon.svg" class="alert-icon" width="23" height="23" alt="alert Icon">
      <div class="banner-content">
        <p class="smaller"><b>Network is currently experiencing a major service outage, impacting large portions of the country.</b></p>
       <p class="smaller">Engineers are working to restore service. At this time, there is no ETA for restoral, but we expect a quick resolution to this critical service outage.</p>
      </div>
  </div>
</div>
