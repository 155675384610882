import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, BehaviorSubject, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseUserProfileService, IMarketingDetails, MobilePlanItem } from '@ztarmobile/zwp-service-backend';
import { ContentfulService } from 'src/services/contentful.service';
import { EbbService } from '@ztarmobile/zwp-service-backend-v2';
import { ACP_CALLBACK_URL } from 'src/environments/environment';
import { ACP_ROUTE_URLS } from './app.routes.names';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export interface InternalStateType {
  [key: string]: any;
}

@Injectable({ providedIn: 'root' })
export class AppState {
  public state: InternalStateType = {};
  public loading = false;
  public isCampaign = false;
  public userLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);
  public callInternalChecks: ReplaySubject<boolean> = new ReplaySubject(1);
  public showPromoForAperiodOfTime: ReplaySubject<boolean> = new ReplaySubject(1);
  public showPromoForAperiodOfTimeObs: Observable<boolean>;
  public displayAcpSection: ReplaySubject<boolean> = new ReplaySubject(1);
  public displayAcpSectionObs: Observable<boolean>;
  public acpAppRes: ReplaySubject<any> = new ReplaySubject(1);
  public acpAppResObs: Observable<any>;
  public acpAppError: ReplaySubject<any> = new ReplaySubject(1);
  public acpAppErrorObs: Observable<any>;
  public acpActiveAppRes: ReplaySubject<any> = new ReplaySubject(1);
  public acpActiveAppResObs: Observable<any>;
  public isMarketingCampaign: Observable<boolean>;
  public isMarktingCampaignReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public campaignQueryParams: Observable<any>;
  public campaignQueryParamsReplySubject: ReplaySubject<any> = new ReplaySubject(1);
  public utmsCampaign: Observable<boolean>;
  public utmsCampaignReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public utmsCampaignParams: Observable<any>;
  public utmsCampaignParamsReplySubject: ReplaySubject<any> = new ReplaySubject(1);
  public isMoneySavingProCampaign: Observable<boolean>;
  public isMoneySavingProReplySubject: ReplaySubject<boolean> = new ReplaySubject(1);
  public globalAlertHeightReplySubject: ReplaySubject<number> = new ReplaySubject(1);
  public globalAlertHeight: Observable<number>;
  public jsonLDString: any;
  public promoRemainingTime: ReplaySubject<{ days: number, hours: number, minutes: number, seconds: number }> = new ReplaySubject(1);
  public promoRemainingTimeObs: Observable<{ days: number, hours: number, minutes: number, seconds: number }>;
  public changePlanData: Observable<{ plan: MobilePlanItem, autoRenew: boolean }>;
  public changePlanDataSubject: ReplaySubject<{ plan: MobilePlanItem, autoRenew: boolean }> = new ReplaySubject(1);
  public resolvedCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedSecondCaptchaSubject: ReplaySubject<any> = new ReplaySubject(1);
  public resolvedCaptcha: Observable<string>;
  public resolvedSecondCaptcha: Observable<string>;
  public resetAndExecuteCaptchaSubject: ReplaySubject<{ action: string }> = new ReplaySubject(1);
  public resetAndExecuteCaptcha: Observable<{ action: string }>;
  public resetAndExecuteSecondCaptchaSubject: ReplaySubject<{ action: string }> = new ReplaySubject(1);
  public resetAndExecuteSecondCaptcha: Observable<{ action: string }>;
  public phonePlanDeviceNetworkSubject : ReplaySubject<string> = new ReplaySubject(1);
  public phonePlanDeviceNetwork: Observable<string>;
  private jsonLd: any = {};

  constructor(private sanitizer: DomSanitizer, private contentfulService: ContentfulService,
    private ebbService: EbbService, private userProfileService: FirebaseUserProfileService) {
    this.isMarketingCampaign = this.isMarktingCampaignReplySubject.asObservable();
    this.campaignQueryParams = this.campaignQueryParamsReplySubject.asObservable();
    this.isMoneySavingProCampaign = this.isMoneySavingProReplySubject.asObservable();
    this.globalAlertHeight = this.globalAlertHeightReplySubject.asObservable();
    this.utmsCampaign = this.utmsCampaignReplySubject.asObservable();
    this.utmsCampaignParams = this.utmsCampaignParamsReplySubject.asObservable();
    this.showPromoForAperiodOfTimeObs = this.showPromoForAperiodOfTime.asObservable();
    this.promoRemainingTimeObs = this.promoRemainingTime.asObservable();
    this.displayAcpSectionObs = this.displayAcpSection.asObservable();
    this.acpAppResObs = this.acpAppRes.asObservable();
    this.acpActiveAppResObs = this.acpActiveAppRes.asObservable();
    this.acpAppErrorObs = this.acpAppError.asObservable();
    this.changePlanData = this.changePlanDataSubject.asObservable();
    this.resolvedCaptcha = this.resolvedCaptchaSubject.asObservable();
    this.resolvedSecondCaptcha = this.resolvedSecondCaptchaSubject.asObservable();
    this.resetAndExecuteCaptcha = this.resetAndExecuteCaptchaSubject.asObservable();
    this.resetAndExecuteSecondCaptcha = this.resetAndExecuteSecondCaptchaSubject.asObservable();
    this.phonePlanDeviceNetwork = this.phonePlanDeviceNetworkSubject.asObservable();
  }

  public clearSessionStorage(): void {
    sessionStorage.removeItem('shippingAddress');
    sessionStorage.removeItem('shippingMethod');
    sessionStorage.removeItem('plan_id');
    sessionStorage.removeItem('payment_id');
    sessionStorage.removeItem('auto_renew');
    sessionStorage.removeItem('purchased');
    sessionStorage.removeItem('activation');
    sessionStorage.removeItem('device');
    sessionStorage.removeItem('emailMdn');
    sessionStorage.removeItem('MoneyProReferral');
    sessionStorage.removeItem('tracked');
    sessionStorage.removeItem('anonymous');
    sessionStorage.removeItem('pendingAddress');
    sessionStorage.removeItem('changeNextCycle');
    sessionStorage.removeItem('skippedPlans');
    sessionStorage.removeItem('termsAccepted');
    sessionStorage.removeItem('referralCode');
    sessionStorage.removeItem('useFromBalance');
    sessionStorage.removeItem('useFromReward');
    sessionStorage.removeItem('removeFromCart');
    sessionStorage.removeItem('editPayment');
    sessionStorage.removeItem('isMigrationSimRemoved');
    sessionStorage.removeItem('selectedPlan');
    sessionStorage.removeItem('checkedDevice');
    sessionStorage.removeItem('phone');
    sessionStorage.removeItem('navigatedToPayment');
    sessionStorage.removeItem('financing');
    sessionStorage.removeItem('financingDetails');
  }

  public setJsonLdData(data): any {
    this.jsonLd = data;
    this.jsonLd = Object.assign({ '@context': 'http://schema.org/' }, this.jsonLd);
    this.jsonLDString = '<script type="application/ld+json">' + JSON.stringify(this.jsonLd) + '</script>';
    this.jsonLDString = this.sanitizer.bypassSecurityTrustHtml(this.jsonLDString);
    return this.jsonLDString;
  }

  public setupFAQsJsonLd(questionsNode): Promise<string> {
    if (!questionsNode || !questionsNode[0]) {
      return;
    }
    const allQuestions = questionsNode[0].fields?.questions;
    const qasPromises = allQuestions.map((question) => {
      return this.contentfulService.getAnswerIdByQstId('questions', question.fields.questionId).toPromise().then(val => {
        if (!!val?.answerId) {
          const answerText = documentToHtmlString(val.answerText);
          return {
            '@type': 'Question',
            'name': question.fields?.questionText,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": answerText
            }
          };
        }
      });
    });

    return Promise.all(qasPromises).then(qasArray => {
      const qas = qasArray.filter(q => q); // Remove undefined values
      const data = {
        '@type': 'FAQPage',
        'mainEntity': qas
      };
      return this.setJsonLdData(data);
    }).catch(error => {
      console.error('Error fetching FAQ data:', error);
    });
  }

  public removeEmptyValues(obj): any {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmptyValues(obj[key]);
      } else if (obj[key] == null || obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }
  public promoTimeRemaining(): void {
    this.contentfulService.getContent('prmotionConfigs').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        const countdownDate = new Date(result?.promoEndDate);
        setInterval(() => {
          const now = new Date();
          const distance = countdownDate.getTime() - now.getTime();

          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          this.promoRemainingTime.next({ days, hours, minutes, seconds });
        }, 1000);
      }
    });
  }
  public validatePromo(): void {
    this.contentfulService.getContent('prmotionConfigs').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        const currentDate = new Date();
        // this is in pacific time
        const pacificCurrentDate = currentDate.toLocaleString("en-US", {
          timeZone: "America/Los_Angeles"
        });
        const promoEndDate = new Date(result?.promoEndDate);
        const pacificPromoEndDate = promoEndDate.toLocaleString("en-US", {
          timeZone: "America/Los_Angeles"
        });
        if (new Date(pacificCurrentDate) <= new Date(pacificPromoEndDate)) {
          this.showPromoForAperiodOfTime.next(true);
        } else {
          this.showPromoForAperiodOfTime.next(false);
        }
      }
    });
  }
  public setMarketingObject(utms): IMarketingDetails {
    const marketingObject: IMarketingDetails = {
      timestamp: new Date().toISOString(), utm_campaign: utms.utm_campaign,
      utm_medium: utms.utm_medium, utm_source: utms.utm_source, attributes: [], url: utms.url, utm_content: !!utms.utm_content ? utms.utm_content : null,
      utm_term: !!utms.utm_term ? utms.utm_term : null
    };
    if (!!utms.agentID || !!utms.agentid) {
      marketingObject.attributes.push({ name: 'agentID', value: !!utms.agentID ? utms.agentID : utms.agentid });
    }
    marketingObject.attributes.push({ name: 'vendorID', value: !!utms && !!utms.vendorID ? utms.vendorID : 'g2g' });
    return marketingObject;
  }
  public checkActivePromo(): boolean {
    const maxDate = new Date('July 05, 2023 14:59:00 GMT-06:00');
    const todayDate = new Date();
    return todayDate <= maxDate;
  }
  public checkInternalEbbApp(): void {
    this.userProfileService.userProfileObservable.subscribe((user) => {
      if (!!user && !!user.ebbId) {
        const callBackUrl = `${ACP_CALLBACK_URL}/${ACP_ROUTE_URLS.BASE}`;
        this.ebbService.getACPApplicationStatus(user.ebbId, user.customerId, callBackUrl).then((res) => {
          if (!!res) {
            this.displayAcpSection.next(true);
            this.acpAppRes.next(res);
            this.acpAppError.next(null);
          } else {
            this.displayAcpSection.next(false);
            this.acpAppRes.next(null);
            this.acpAppError.next(null);
          }
        }, (error) => {
          if (!!error?.error && !!error?.error?.errors[0] && error?.error?.errors[0]?.code === 'APP_CLOSED_OR_EXPIRED') {
            this.displayAcpSection.next(true);
            this.acpAppRes.next(null);
            this.acpAppError.next(error);
          } else {
            this.displayAcpSection.next(false);
            this.acpAppRes.next(null);
            this.acpAppError.next(error);
          }
        });
      } else if (!!user && !user.ebbId) {
        this.ebbService.getActiveInternalApplication(user.customerId).then((res) => {
          if (!!res) {
            this.displayAcpSection.next(true);
            this.acpActiveAppRes.next(res);
          } else {
            this.displayAcpSection.next(false);
            this.acpActiveAppRes.next(null);
          }
        }, (error) => {
          this.displayAcpSection.next(false);
          this.acpActiveAppRes.next(null);
        });
      }
    });
  }
  public calculateTotalPlanPrices(plan: MobilePlanItem): any {
    if (!!plan) {
      if (!!plan?.subscriptionPrice && !!plan?.isSpecialPromotion && ((!!plan?.specialPromotion?.promotionAmount && plan?.specialPromotion?.promotionAmount > 0) || !!plan?.specialPromotion?.promotionDiscount)) {
        if (!!plan?.specialPromotion?.promotionAmount && plan?.specialPromotion?.promotionAmount > 0 && !plan?.specialPromotion?.autoPayRequired)
          {return (plan?.subscriptionPrice - plan?.specialPromotion?.promotionAmount);}
        else if(!!plan?.specialPromotion?.promotionAmount && plan?.specialPromotion?.promotionAmount > 0 && plan?.specialPromotion?.autoPayRequired) {
          {return (((plan?.subscriptionPrice - plan?.promoPrice) - plan?.specialPromotion?.promotionAmount))}
        }
        else if (!!plan?.specialPromotion?.promotionDiscount && !plan?.specialPromotion?.autoPayRequired) {
          const discountAmountValue = plan?.specialPromotion?.promotionDiscount.split('%')[0];
          const discountValue = parseInt(discountAmountValue, 10);
          return plan?.subscriptionPrice - (plan?.subscriptionPrice * (discountValue / 100))
        } else if (!!plan?.specialPromotion?.promotionDiscount && !!plan?.specialPromotion?.autoPayRequired) {
          const discountAmountValue = plan?.specialPromotion?.promotionDiscount.split('%')[0];
          const discountValue = parseInt(discountAmountValue, 10);
          return ((plan?.subscriptionPrice - plan?.promoPrice) - ((plan?.subscriptionPrice  - plan?.promoPrice)* (discountValue / 100)));
        }
      } else {
        // prmo price is the suto renew
        return ((plan?.subscriptionPrice - plan?.promoPrice));
      }
    }
  }
}
