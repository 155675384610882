import { Injectable } from '@angular/core';

import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { Observable } from 'rxjs';
import { RouterHelperService } from '@ztarmobile/zwp-services-util';
import { map, take } from 'rxjs/operators';

@Injectable()
export class AuthGuardService  {
  constructor(private router: RouterHelperService, private simpleAuthService: SimpleAuthService) {
  }

  public canActivate(): Observable<boolean> {
    return this.simpleAuthService.userState.pipe(map((authState) => {
      if (!!authState && !authState.isAnonymous) {
        this.router.navigate(['/home']);
        return false;
      } else {
        return true;
      }
    }), take(1));
  }
}

export const AUTH_GUARD_PROVIDERS = [
  AuthGuardService
];
