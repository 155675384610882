import { PlatformLocation } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class PlansFeaturesContext {
  public title?: string;
  public planData?: string;
}
@Component({
  selector: 'app-plans-features',
  templateUrl: './plans-features.component.html',
  styleUrl: './plans-features.component.scss'
})
export class PlansFeaturesComponent {
  public context: any;

   constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<PlansFeaturesContext>,private location: PlatformLocation){
    location.onPopState(() => { this.beforeDismiss();this.dialog.close();});
    this.context = data;
  }

   beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public closeDialog(): void {
    this.beforeDismiss();
    this.dialog.close(false);
  }
}
