<div class="modal">
    <div class="container model-container plans-features-modal">
        <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
        <div class="modal-header">
            <h4 class="title">{{!!context?.title? context?.title : 'Good2Go Mobile Plans Include:'}}</h4>
        </div>
        <div class="modal-body-section">
            <div class="modal-body">
                <div class="plan-feature">
                    <img src="/assets/img/high-speed-img.svg" alt="high speed data" width="40" height="40">
                    <p class="smallest"><span [class.bold]="!!context?.planData">{{!!context?.planData? context?.planData :'Monthly'}} </span> <b>high-speed</b> data, then data slowed.</p>
                </div>
                <div class="plan-feature">
                    <img src="/assets/img/final-price.svg" alt="final-price" width="40" height="40">
                    <p class="smallest"><b>$5</b> AutoPay <b>Discount</b> on total price</p>
                </div>
                <div class="plan-feature">
                    <img src="/assets/img/destinations.svg" alt="destinations" width="40" height="40">
                    <p class="smallest">Unlimited Calling to over <b>60+</b> destinations.</p>
                </div>
                <div class="plan-feature">
                    <img src="/assets/img/5g.svg" alt="5g speed" width="40" height="40">
                    <p class="smallest">Powered by America’s best <b>5G</b> networks</p>
                </div>
                <div class="plan-feature">
                    <img src="/assets/img/compatible.svg" alt="compatible device" width="40" height="40">
                    <p class="smallest"><b>Compatible</b> with unlocked
                        iPhone & Android devices.</p>
                </div>
                <div class="plan-feature">
                    <img src="/assets/img/SIM.svg" alt="SIM" width="40" height="40">
                    <p class="smallest"><b>FREE</b> SIM Card. <b>FREE</b> Shipping.</p>
                </div>
            </div>
        </div>
    </div>
</div>