<div class="banner-container">
  <div class="banners-swiper-container">
    <div class="banner-section" *ngIf="!showHalloweenBanner">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.webp'
          media="(max-width: 639.9px)" type="image/webp">
        <source width="639" height="520" srcset='assets/img/banners/Mobile.png'
          media="(max-width: 639.9px)" type="image/png">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.webp'
          media="(max-width: 1024.9px)" type="image/webp">
        <source width="1024" height="500" srcset='assets/img/banners/Tablet.png'
          media="(max-width: 1024.9px)" type="image/png">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.webp'
          media="(min-width: 1025px)" type="image/webp">
        <source width="2000" height="500" srcset='assets/img/banners/Desktop.png'
          media="(min-width: 1025px)" type="image/png">
        <img width="2000" height="500" src="assets/img/banners/Desktop.png"
          alt="Unlimited Talk, Text & Data for $25 " title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <h2 class="header">UNLIMITED</h2>
        <h4 class="subheader">Talk, Text & Data for</h4>
        <div class="price-label">
          <img src="assets/img/price-label.svg" alt="price label" width="250" height="134" />
          <div class="price">
            <p class="amount"><span class="dollar">$</span>25</p>
            <p class="mo">/mo</p>
          </div>
        </div>
        <button class="button primary" (click)="goToOfferLanding()">Claim Offer</button>
      </div>
    </div>
    <div class="banner-section halloween" *ngIf="showHalloweenBanner">
      <picture class="banner-image-container">
        <source width="639" height="520" srcset='assets/img/banners/halloween-mobile.png'
          media="(max-width: 639.9px)" type="image/png">
        <source width="1024" height="500" srcset='assets/img/banners/halloween-tablet.png'
          media="(max-width: 1024.9px)" type="image/png">
        <source width="2000" height="500" srcset='assets/img/banners/halloween-desktop.png'
          media="(min-width: 1025px)" type="image/png">
        <img width="2000" height="500" src="assets/img/banners/halloween-desktop.png"
          alt="Unlimited Talk, Text & Data for $25 " title="Unlimited Talk, Text & Data for $25" loading="eager">
      </picture>
      <div class="banner-content-container">
        <h2 class="header">UNLIMITED</h2>
        <p class="subheader">Talk, Text & Data for</p>
        <div class="price-label">
          <img src="assets/img/price-label.svg" alt="price label" width="250" height="134" />
          <div class="price">
            <p class="amount"><span class="dollar">$</span>25</p>
            <p class="mo">/mo</p>
          </div>
        </div>
        <button class="button primary" (click)="goToOfferLanding()">Get Halloween Offer</button>
      </div>
      <div class="gif-section">
        <picture>
          <source srcset="assets/img/halloween.gif" type="image/gif">
          <img src="assets/img/halloween.gif" alt="Happy Halloween" height="500" width="590" />
      </picture>
      </div>
    </div>
  </div>
</div>